// import customProtocolCheck from 'custom-protocol-check';
import { useEffect } from 'react';
import { Button } from 'react-daisyui';
import { isMobile } from 'react-device-detect';
import toast, { type Toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

import AppStoreIcon from '@/assets/common/ic_appstore.svg?react';
import PlayStoreIcon from '@/assets/common/ic_playstore.svg?react';

// eslint-disable-next-line react-refresh/only-export-components
function DownloadHarmoToast(props: Toast) {
  const { id, visible } = props;
  return (
    <div
      className={twMerge(
        visible ? 'animate-enter' : 'animate-leave',
        'max-w-md bg-base-100 shadow-lg rounded-3xl pointer-events-auto flex ring-1 ring-black ring-opacity-5',
      )}
    >
      <div className="flex flex-col justify-between gap-4 p-4">
        <p className="flex items-center justify-between text-base font-bold">
          harmoアプリをはじめる
          <Button type="button" size="sm" color="ghost" shape="circle" onClick={() => toast.dismiss(id)}>
            ✕
          </Button>
        </p>

        <div className="flex justify-center gap-4">
          <a
            href="https://apps.apple.com/jp/app/harmo%E3%81%8A%E3%81%8F%E3%81%99%E3%82%8A%E6%89%8B%E5%B8%B3-%E3%83%8F%E3%83%AB%E3%83%A2/id723317758"
            target="_blank"
            className="transition-all active:opacity-60 hover:opacity-80"
            rel="noreferrer"
          >
            <AppStoreIcon />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.sony.harmo"
            target="_blank"
            className="transition-all active:opacity-60 hover:opacity-80"
            rel="noreferrer"
          >
            <PlayStoreIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export function showDownloadHarmoToast() {
  const id = toast.custom(DownloadHarmoToast, {
    id: 'download-harmo-toast',
    position: 'bottom-center',
    duration: 0x7fffffff,
  });
  return id;
}

export function useShowDownloadHarmoToast() {
  // useEffect(() => {
  //   customProtocolCheck(
  //     'harmo://home',
  //     () => {
  //       showDownloadHarmoToast();
  //     },
  //     () => {
  //       console.log('harmo App has been found.');
  //     },
  //     2000,
  //     () => {
  //       showDownloadHarmoToast();
  //     },
  //   );
  // }, []);
  useEffect(() => {
    if (!isMobile) {
      return;
    }
    showDownloadHarmoToast();
    return () => closeDownloadHarmoToast();
  }, []);
}

export function closeDownloadHarmoToast() {
  toast.remove('download-harmo-toast');
}
